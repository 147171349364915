@import "_vars";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

*{
    box-sizing: border-box;
}

*:not(.Toastify *){
    color: $mainFontColor;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 { 
    text-align: center;
    font-weight: 700;

    &.menor { font-size: 1.9em; }
}

.menor { font-size: 0.875em; }

h2{
    font-size: 1.5rem;
    font-weight: 700;
}

h3{
    font-size: 1.2rem;
    font-weight: 500;
}

h5{
    font-size: 1rem;
}

label {
    margin-left: 15px;
    color: $newGray !important;
}

input{ 
    color: $mainFontColor !important;
    border-color: $supportFontColor !important;
    border-radius: 6px;

    &:focus { box-shadow: none !important; }

    &.eye-pass { position: relative; }
}

.lnr-eye {
    position: absolute;
    top: 19px;
    right: 15px;
    cursor: pointer;
    font-size: 20px;
    color: $newGray;

    &.space { right: 27px; }
}

strong { font-weight: 500; }

img { 
    max-width: 100%;
    margin: 0;
}

a { 
    display: inline-block;
    width: auto !important;    
    color: $mainColor !important;
    font-weight: 500;
    cursor: pointer !important;
    text-decoration: none;

    &:hover:not(.btn), 
    &.on-click:hover { text-decoration: underline; }

    .btn:hover { text-decoration: none; }

    figure:hover { opacity: .8; }

    &.din-link:not([href]) {
        opacity: .2 !important;
        cursor: default !important;
        &:hover { opacity: .2; }
    }
}

footer{
    background-color: darken($mainFontColor, 15%);
    padding: 60px;

    & * { color: $supportFontColor !important; }

    h1, h2, h3, h4, h5 { color: white !important; }
}

// ==========================================
// CLASSES
// ==========================================
.subtitle{
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
}

.help-text{
    font-size: 0.7rem;
    font-weight: 400;
}

p.legenda,
p.legenda li { 
    color: $mainFontColor; 
    font-size: 0.875em;
    text-align: left;
}

p.legenda { margin-top: 8px; }

.normal-p{
    font-size: 0.9;
    font-weight: 400;
}

.inative { opacity: .2; }

.btn { 
    font-size: .875em;
    white-space: nowrap;
}

.btn-primary{
    background-color: $mainColor;
    color: white !important;
    font-weight: 400;
    border: none;
    border-radius: 60px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: min(80px, 20vw);
    padding-right: min(80px, 20vw);
    text-transform: uppercase;
    transition: .25s;
    box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);

    &:hover, &:focus, &:active{
        background-color: darken($mainColor, 20%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }

    &:disabled{
        background-color: lighten($mainColor, 5%);
        box-shadow: 0px .5rem 10px 0px lighten($mainColor, 20%);
        border: none;
    }

    *{
        color: white !important;
    }

    .spinner-grow{
        width: 21px;
        height: 21px;
    }

    &.white {
        box-sizing: border-box;
        background-color: #fff;
        color: $mainColor !important;
        font-weight: 400;
        border: 1px solid $mainColor;
        transition: .25s;   
        
        &:hover, &:focus, &:active{
            background-color: darken($mainColor, 20%);
            color: #fff !important;
            border-color: darken($mainColor, 20%);
        }
    }

    &.fluid {
        padding-left: 60px;
        padding-right: 60px;
    }    
    &.fluid-slim {
        padding-left: 20px;
        padding-right: 20px;
    }    
    &.no-shadow { box-shadow: none; }    
}

.form-control {
    height: calc(3.2rem + 3px);
    &:focus { box-shadow: none !important; }
}

.was-validated { 
    .form-control {
        &:valid { 
            border-color: $supportFontColor  !important;
            background-image: none !important;
        }
        &:invalid, &.is-invalid { border-color: $mainColor !important; }

        &.manual-error { 
            border-color: $mainColor !important;
            padding-right: calc(1.5em + 0.75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);            
        }
    }
}


.form-check-input {
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.10em;
    border-radius: 4px;
    cursor: pointer;

    &:checked { background-color: #747474 !important; }
    &:invalid, &.is-invalid { border-color: $mainColor !important; }
}

#dropdown-menu{
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    text-align: center;
    box-shadow: none;
}

.dropdown-menu-container{
    left: auto !important;
    right: 13px !important;
    min-width: 200px;
    padding: 30px 0;
    margin-top: 5px !important;
    border-radius: 10px;
    border: 1px solid #dadada;
    box-shadow: 5px 4px 12px #ededed;

    a{
        font-size: .875em;
        font-weight: 400;
        color: $mainFontColor !important;
        margin: 0 30px 10px 30px !important;
        padding: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;        
        &:last-child { margin-bottom: 0 !important; }

        &:hover { font-weight: 700; }
    }
}

// .figure-stores{
//     img{
//         max-width: 210px;
//     }
// }

.mainContent {
    max-width: 768px;
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;        
    margin-bottom: 120px;

    // border: 1px solid red;
}

// Toaster
.Toastify__toast {
    font-family: 'Montserrat', sans-serif;
    font-size: .875em;
    border-radius: 15px;
}
.Toastify__toast-body { padding: 15px; }
.Toastify__toast-container {
    width: auto;
    max-width: 400px;
}
.Toastify__toast-icon { margin-inline-end: 20px; }
.Toastify__toast--warning { background: $mainFontColor !important; }
.Toastify__toast--error { background: $mainColor !important; }

.Toastify__toast--success { 
    background: $bgColor !important; 
    color: $mainFontColor !important;

    .Toastify__toast-icon { color: $mainColor !important; }
}


.quadro-contratacao{
    padding: 60px 30px;
    width: min(1140px, 100vw);
    box-sizing: border-box;

    & > .row{
        box-sizing: border-box;
        & > div{
            box-sizing: border-box;
        }
    }
}

.container-notFound{
    padding: 30px;
    min-height: 60vh;
    justify-items: center;
    align-items: center;
    position: relative;
    top: max(-10vh, -120px);
    display: grid;

    h1{
        font-size: 2rem;
    }
}

#loading-animation{
    margin: 0 auto;
    width: 440px;
    max-width: 100%;
    height: 100vh;
    position: relative;
    top: -200px;
    z-index: -1;
}

.menu-principal {
    background-color: #fff !important;
    border-bottom: 1px solid $supportFontColor;
    padding: 20px 0;
    margin-bottom: 60px;

    .container-menu {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0;
    
        .figure { padding: 0; margin: 0; }    
        .navbar-brand {
            margin-left: 30px;
            padding: 0;
            max-width: 150px; 
        }

        .navbar-toggler {
            border: 1px solid purple !important;
    
            margin-right: 30px;
            text-transform: uppercase;
            font-size: .875em;
            border: 0 !important;
            box-shadow: none;
        }
    
        .menu-right {
            justify-content: flex-end;
            .menu-titulo { margin-right: 30px; }
        }        
    }
}

.seguro-item{
    padding: 60px 30px;
    border-radius: 10px;
}

.apps {
    display: flex;
    justify-content: space-between;

    a {
        margin-right: 15px;
        :last-child { margin-right: 0; }
    }
}

.bkg-convite {position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;    
    background-image: url("../assets/animations/convite.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 40px;
    z-index: -1;
}

// animação "escrevendo"
.anim-writing {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid $mainColor;
}

.anim-typing {
    animation:  blinkCursor 600ms steps(40) infinite normal both,
                typing 1s steps(40) 1.5s normal both,
                stopCursor 0.2s 6s normal both;
}

@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}
@keyframes blinkCursor {
    from { border-right-color: $mainColor; }
    to { border-right-color: transparent; }
}
@keyframes stopCursor {
    // from { border-right-color: $mainColor; }
    to { border-right-color: transparent; }
}


// ==========================================
// MOBILE
// ==========================================
@media only screen and (max-width: 992px) {
    .menu-principal {
        .navbar-brand { 
            width: 130px !important;
            margin-left: 15px !important; 
        }

        .navbar-toggler,
        .menu-titulo { margin-right: 15px !important; }

        .mob-opcoes {
            margin-top: 20px;
            text-align: center;

            a { 
                border-top: 1px solid $supportFontColor; 
                color: $mainFontColor !important;
                font-weight: normal;
                font-size: .875em;
                padding: 20px 0;

                &:last-child { padding-bottom: 0; }
            }               
        }
    }
}

@media only screen and (max-width: 768px) {
    .container-menu { padding: 0 15px; }
    .menu-principal { margin-bottom: 60px; }    

    .quadro-contratacao {
        padding: 60px 30px;
        width: 100vw;
        position: relative;
    }

    .seguro-item { padding: 15px; }

    // helpers
    .ps-xs-default { padding-left: 15px !important; }
    .form-check-label { display: inline-grid; }    
    .fluid-slim { margin-top: 20px; }
    .roundIcon { 
        width: 50px !important;
        height: 50px !important;
        padding: 11px !important;
     }
    
    body { font-size: 14px; }
    h1 { font-size: 1.5em; &.menor { font-size: 1.4em; } }    
    h2 { font-size: 1.3em; }
    h5 {font-size: .875em; font-weight: 600; }
    .subtitle { font-size: 1em; }
}

@media only screen and (max-width: 320px) {
    h1 { 
        font-size: 1.3em;
        &.menor { font-size: 1.2em; }
    } 
}


// ==========================================
// HELPERS
// ==========================================
.bg-bgColor{ background-color: $bgColor !important }

.color-main{ color: $mainColor !important}
.color-mainFont{ color: $mainFontColor !important}

.container-m{ max-width: min(768px, 90vw); padding: 0 15px; margin: auto;}

.font-medium{ font-weight: 500; }
.font-bold{ font-weight: 700; }

.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

ul.no-disc {
    list-style-type: none;
    padding-inline-start: 0;
}

.roundIcon{
    background-color: $mainColor;
    width: 80px;
    height: 80px;
    padding: 15px;

    border-radius: 50%;

    img{
        object-fit: contain;
        object-position: center;
    }

    &.special{
        img{
            width: 83%;
        }
    }
}
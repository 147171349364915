$mainColor: #ee3f60;
$mainFontColor: #4a4a4a;
$supportFontColor: #dadada;
$newGray: #b5b5b5;
$bgColor: #f7f7f7;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25),    //4px
      2: ($spacer * .5),     //8px
      3: $spacer,            //16px
      4: ($spacer * 1.5),    //24px
      5: ($spacer * 3),      //48px
      6: ($spacer * 4),      //64px
      7: ($spacer * 5),      //80px
      8: ($spacer * 6.25),   //100px
      9: ($spacer * 7.5),    //120px
      10: ($spacer * 9.375)  //150px
    ),
    $spacers
);

$form-floating-height: 3.4rem; // altura input floating

@function min($numbers...) {
    @return m#{i}n(#{$numbers});
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}
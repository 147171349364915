@import "../../styles/_vars";

.tokenInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;

    input{
        line-height: 40px;
        width: 3rem;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(3){
            margin-right: 30px;
        }

        &:focus{
            box-shadow: 0 0 5px 0.25rem lighten($mainColor, 20%) !important;
        }
    }    

    @media only screen and (max-width: 420px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        justify-items: center;

        input{
            width: 4rem;
            margin-bottom: 10px;

            &:nth-child(3){
                margin-right: 0px!important;
            }
        }        
    }
}